import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { MarketGroup } from '../models/market-dashboard';

export interface IMarketGroupState extends EntityState<MarketGroup> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'dashboardGroup', resettable: true })
export class MarketGroupStore extends EntityStore<
  IMarketGroupState,
  MarketGroup
> {
  constructor() {
    super();
  }
}
