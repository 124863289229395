import { Injectable } from '@angular/core';
import {
  IndividualTimeFrame,
  TimeFrame,
  TimeFrameUtilService,
} from '@backoffice-frontend/shared/bo/util-masterdata';
import {
  ChartTimeSeries,
  TimeSeriesIdentifierInput,
} from '@backoffice-frontend/time-series-identifier/api';

import { GraphqlService } from '@clean-code/shared/util-graphql';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExportTableDataService {
  constructor(private graphqlService: GraphqlService) {}

  getTimeSeriesChartData$(
    ids: TimeSeriesIdentifierInput[],
    timeFrame?: TimeFrame,
    showIntraDay = true
  ): Observable<ChartTimeSeries[]> {
    const query = `
    query($symbols: [TimeSeriesInput!]!, $timeFrame: TimeSeriesTimeFrame, $individualTimeFrame: IndividualTimeFrameInput, $showIntraDay:Boolean!){
      chartTimeSeries(symbols: $symbols, timeFrame: $timeFrame, individualTimeFrame: $individualTimeFrame, showIntraDay: $showIntraDay)
      {
        values
        {
          value
          tradeDate
        }
        frontOfficeName
        longName
        sortOrder
        deliveryDay
        areaMarketArea
        {
          name
        }
        category
        unit
        error {
          message
        }
      }
    }`;

    const variables = {
      symbols: ids,
      timeFrame: null as string,
      individualTimeFrame: null as IndividualTimeFrame,
      showIntraDay,
    };

    TimeFrameUtilService.setTimeFrameVariable(timeFrame, variables);

    return this.graphqlService.query<ChartTimeSeries[]>(query, variables, null);
  }
}
