import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  MarketColumn,
  MarketDashboard,
  MarketGroup,
} from './../models/market-dashboard';

@Injectable()
export class ColumnStateService {
  private _column: MarketColumn;
  public changeEvent: Subject<MarketDashboard>;

  public expendedMode$ = new BehaviorSubject<boolean>(false);

  public constructor() {
    this.changeEvent = new Subject<MarketDashboard>();
  }

  public get column(): MarketColumn {
    return this._column;
  }

  public set column(d: MarketColumn) {
    this._column = d;
  }

  public addGroup(group: MarketGroup) {
    this._column.summaries.push(group);
  }

  public toggleExpendedMode() {
    this.expendedMode$.next(!this.expendedMode$.value);
  }

  // public removeColumn(column: MarketColumn) {
  //   this.dashboardInternal.cols.re(column);
  // }
}
