import { Injectable } from '@angular/core';
import { TimeSeriesInput } from '@backoffice-frontend/time-series-identifier/api';
import { Observable } from 'rxjs';
import { MarketDashboardDetailsDataService } from '../infrastructure/dashboard-details.data.service';
import { TimeSeriesIndexSummary } from '../models/index-details-table';

@Injectable({ providedIn: 'root' })
export class DashboardDetailsFacade {
  constructor(private service: MarketDashboardDetailsDataService) {}

  public getIndexDetails$(
    symbol: TimeSeriesInput,
    date: Date
  ): Observable<TimeSeriesIndexSummary> {
    return this.service.getIndexDetails$(symbol, date);
  }

  public getCompactIndexDetails$(symbol: TimeSeriesInput, date: Date) {
    return this.service.getCompactIndexDetails$(symbol, date);
  }
}
