import { Injectable } from '@angular/core';
import {
  TimeSeriesCompact,
  TimeSeriesInput,
} from '@backoffice-frontend/time-series-identifier/api';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { Observable } from 'rxjs';
import { TimeSeriesIndexSummary } from './../models/index-details-table';

@Injectable({
  providedIn: 'root',
})
export class MarketDashboardDetailsDataService {
  constructor(private graphqlService: GraphqlService) {}

  getIndexDetails$(
    symbol: TimeSeriesInput,
    endDate: Date
  ): Observable<TimeSeriesIndexSummary> {
    const query = `
    query($symbol: TimeSeriesInput!, $endDate: DateTime) {
      timeSeriesIndexSummary(symbol: $symbol, endDate: $endDate){
        deliveryDay
        priceDetails
        {
          intradayPrice
          intradayDateTime
          intradayChange
          yearHigh
          yearLow
          firstDayOfYear
          firstDayOfYearDiff
          oneYearHigh
          oneYearLow
          oneYearChangeDate
          oneYearChange
          unit
          lastClosingPrice
          lastClosingDate
          closingChange
          secondLastClosingPrice
          secondLastClosingDate
        }
        volumeDetails {
          previewDayVolume
          diff
        }
      }
    }`;

    return this.graphqlService.query<TimeSeriesIndexSummary>(query, {
      symbol: symbol.identifier,
      endDate,
    });
  }

  getCompactIndexDetails$(
    symbol: TimeSeriesInput,
    endDate: Date
  ): Observable<TimeSeriesCompact> {
    const query = `
    query($symbol: TimeSeriesInput!, $endDate: DateTime) {
      timeSeriesIndexCompactSummary(symbol: $symbol, endDate: $endDate){
        deliveryDay
        currentValue
        currentValueDate
        previousValue
        previousValueDate
        differenceRelative
        differenceAbsolute
        unit
      }
    }`;

    return this.graphqlService.query<TimeSeriesCompact>(query, {
      symbol: symbol.identifier,
      endDate,
    });
  }
}
