import { Injectable } from '@angular/core';
import { ID } from '@clean-code/shared/common';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MarketColumnDetailStore } from '../+state/details-column.store';
import { MarketColumn, MarketColumnDetail } from './../models/market-dashboard';
import { MarketGroupDataService } from './group.data.service';

@Injectable({ providedIn: 'root' })
export class MarketColumnDetailDataService {
  public static readonly entityName = 'marketColumnDetail';
  private readonly inputModelName = 'MarketColumnDetailInput';
  public static readonly columnGetParams = `
        id
        items
        {
            ${MarketGroupDataService.marketGroupParams}
        }
        position
    `;

  constructor(
    private store: MarketColumnDetailStore,
    private graphqlService: GraphqlService
  ) {}

  getAll$(id: ID): Observable<MarketColumnDetail[]> {
    const query = `
            query($id: Int!) {
                ${MarketColumnDetailDataService.entityName}GetAll(id: $id) {
                    ${MarketColumnDetailDataService.columnGetParams}
                }
            }
        `;

    return this.graphqlService.query<MarketColumnDetail[]>(query, { id });
  }

  getById$(id: ID): Observable<MarketColumnDetail> {
    const query = `
            query($id: Int!) {
                ${MarketColumnDetailDataService.entityName}GetById(id: $id) {
                    ${MarketColumnDetailDataService.columnGetParams}
                }
            }
        `;

    return this.graphqlService.query<MarketColumnDetail>(query, { id });
  }

  add$(input: MarketColumnDetail): Observable<MarketColumnDetail> {
    const mutation = `
            mutation ($input: ${this.inputModelName}!) {
                ${MarketColumnDetailDataService.entityName}Add(input: $input) {
                    ${MarketColumnDetailDataService.columnGetParams}
                }
            }
        `;

    const variables = {
      input,
    };

    return this.graphqlService
      .mutation<MarketColumnDetail>(mutation, variables)
      .pipe(
        tap((value: MarketColumnDetail) => this.store.upsert(value.id, input))
      );
  }

  update$(input: MarketColumn): Observable<MarketColumnDetail> {
    const mutation = `
            mutation ($input: ${this.inputModelName}!) {
                ${MarketColumnDetailDataService.entityName}Update(input: $input) {
                    ${MarketColumnDetailDataService.columnGetParams}
                }
            }
        `;

    const variables = {
      input,
    };

    return this.graphqlService
      .mutation<MarketColumnDetail>(mutation, variables)
      .pipe(
        tap((value: MarketColumnDetail) => this.store.upsert(value.id, input))
      );
  }

  delete$(id: ID): Observable<boolean> {
    const mutation = `
            mutation {
                ${MarketColumnDetailDataService.entityName}Delete(id: ${id})
            }
        `;

    return this.graphqlService
      .mutation<boolean>(mutation, {})
      .pipe(tap(() => this.store.remove(id)));
  }
}
