@if (isLoading$ | async) {
  <mat-progress-bar
    mode="indeterminate"
  ></mat-progress-bar>
}

<div class="flex flex-col">
  <div
    class="flex flex-col sm:flex-row sm:space-x-2 items-baseline justify-center"
    >
    <div>
      <span class="text-[13px] font-bold">{{
          'market-dashboard-details.DELIVERY_DAY' | transloco
        }}</span>
      <mat-form-field
        (click)="picker.open()"
        class="text-xs ml-2 !w-[140px] mb-2 mr-2.5"
        >
        <input
          [formControl]="dateControl"
          [matDatepicker]="picker"
          [max]="maxSpotDate"
          [readonly]="true"
          class="!text-[13px] hover:cursor-pointer"
          matInput
          />
          <mat-datepicker-toggle
            [for]="picker"
            class="text-base"
          matSuffix
          ></mat-datepicker-toggle>
        <mat-datepicker #picker [touchUi]="isMobile"></mat-datepicker>
        </mat-form-field>
        @if (dateControl.getRawValue() !== null) {
          <fa-icon
        (click)="setDate(-1)"
            [icon]="faChevronLeft"
            class="cursor-pointer mr-2.5"
            matTooltip="{{ 'market-dashboard-details.PREVIOUS_DAY' | transloco }}"
          ></fa-icon>
        }
        @if (dateControl.getRawValue() !== null) {
          <fa-icon
        (click)="setDate(1)"
            [icon]="faChevronRight"
        [ngClass]="{
          'text-gray-200 cursor-default':
            dateControl.getRawValue().startOf('day').diff(maxSpotDate) === 0
        }"
        class="cursor-pointer"
            matTooltip="{{ 'market-dashboard-details.NEXT_DAY' | transloco }}"
          ></fa-icon>
        }
      </div>

      @if (spotLabel) {
        <div class="text-gray-500 font-bold">
          {{ spotValue | currency : 'EUR' : 'symbol' : '.2-2' }}/MWh
        </div>
      }
    </div>

    <cc-chart-container [isLoading]="isLoading$ | async">
      <cc-line-chart
        [data]="data$ | async"
        [legend]="legend"
      [xAxesLabelFormat]="{ format: 'HH:mm' }"
      chart-content
      class="min-h-[18rem]"
      [zoomable]="false"
      ></cc-line-chart>
  </cc-chart-container>
</div>
