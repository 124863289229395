import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TrendIconComponent } from './trend-icon.component';

@NgModule({
  imports: [CommonModule, FontAwesomeModule],
  declarations: [TrendIconComponent],
  exports: [TrendIconComponent],
})
export class TrendIconModule {}
