import { Injectable } from '@angular/core';
import { EnumValue } from '@clean-code/shared/bo/util-enum';
import { ID } from '@clean-code/shared/common';
import { Observable } from 'rxjs';
import { MarketDashboardDataService } from '../infrastructure/dashboard.data.service';
import { MarketDashboard } from '../models/market-dashboard';

@Injectable({ providedIn: 'root' })
export class DashboardFacade {
  constructor(private service: MarketDashboardDataService) {}

  public timeFrames$(): Observable<EnumValue[]> {
    return this.service.timeFrames$();
  }

  add$(input: MarketDashboard) {
    return this.service.add$(input);
  }

  update$(input: MarketDashboard) {
    return this.service.update$(input);
  }

  getAll$(): Observable<MarketDashboard[]> {
    return this.service.getAll$();
  }

  getById$(_id: ID) {
    return this.service.getById$(_id);
  }

  getByName$(_name: string) {
    return this.service.getByName$(_name);
  }

  delete$(_id: ID) {
    return this.service.delete$(_id);
  }
}
