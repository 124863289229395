import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DashboardFacade } from '@backoffice-frontend/market/domain';
import { TimeFrame } from '@backoffice-frontend/shared/bo/util-masterdata';
import { AroonChartData } from '@backoffice-frontend/shared/ui-statistics';
import {
  AroonSettings,
  AroonSummary,
  LineChartBaseChart,
  TimeSeriesFacade,
  TimeSeriesInput,
} from '@backoffice-frontend/time-series-identifier/domain';
import { indicate } from '@clean-code/shared/common';
import {
  DevExtremeLineChartComponent,
  LineChartDataModel,
  LineChartModel,
} from '@clean-code/shared/components/ui-devextreme-charts';
import { AroonTrendIconModule } from '@clean-code/shared/components/ui-trend-icon';
import { TranslocoModule } from '@jsverse/transloco';
import { TitleOptions } from 'chart.js';
import { EMPTY } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'fof-aroon-chart',
  templateUrl: './aroon-chart.component.html',
  imports: [
    CommonModule,
    MatProgressBarModule,
    TranslocoModule,
    DevExtremeLineChartComponent,
    AroonTrendIconModule,
  ],
})
export class AroonChartComponent extends LineChartBaseChart<AroonSettings> {
  @Input()
  public set identifier(value: TimeSeriesInput) {
    this.identifier$.next(value);
  }

  @Input()
  public set timeFrame(value: TimeFrame) {
    this.selectedTimeFrame$.next(value);
  }

  @Input()
  public set settings(value: AroonSettings) {
    this.chartSettings$.next(value);
  }

  @Input()
  public legend = false;

  public yAxisTitle: TitleOptions;

  public data$ = this.paramChange$.pipe(
    switchMap(
      ([value, settings, timeFrame]: [
        TimeSeriesInput,
        AroonSettings,
        TimeFrame,
      ]) => {
        return this.timeSeriesFacade
          .getAroonChartData$(value.identifier, settings, timeFrame)
          .pipe(indicate(this.isLoading$));
      },
    ),
    map((value: AroonSummary) => {
      const array = new Array<any>();

      const aroonUp = value.values.map(
        (val: AroonChartData) =>
          ({
            date: new Date(val.date),
            value: val.aroonUp,
          }) as LineChartDataModel,
      );
      const aroonDown = value.values.map(
        (val: AroonChartData) =>
          ({
            date: new Date(val.date),
            value: val.aroonDown,
          }) as LineChartDataModel,
      );

      array.push({
        label: 'Aroon Up',
        data: aroonUp,
        yAxisTitle: value?.unit,
      } as LineChartModel);
      array.push({
        label: 'Aroon Down',
        data: aroonDown,
        yAxisTitle: value?.unit,
      } as LineChartModel);

      return { chart: array, table: value.table };
    }),
    // this.checkScalesUnit()
  );

  details$ = EMPTY;

  constructor(
    private timeSeriesFacade: TimeSeriesFacade,
    public dashboardFacade: DashboardFacade,
  ) {
    super();
  }
}
