<cc-progress-bar [isLoading]="isLoading$ | async"></cc-progress-bar>
@if (data$ | async; as data) {
  <div class="flex flex-col lg:flex-row h-full space-x-2 md:space-x-0">
    <cc-line-chart [data]="data.chart" [legend]="legend()"></cc-line-chart>
    @if (data.table.length > 0) {
      <div class="flex flex-col w-full lg:w-1/4">
        <h4 class="text-center">
          {{ 'market-dashboard-details.COMPARISON' | transloco }}
        </h4>
        <table class="table-fixed">
          <tbody>
            @for (item of data.table; track item) {
              <tr>
                <td class="md:w-56">
                  {{ item.name }}
                </td>
                <td class="md:w-28">{{ item.value | number: '.2-2' }}</td>
                <td>{{ item.unit }}</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    }
  </div>
}
