import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { MarketColumn } from '../models/market-dashboard';

export interface IMarketColumnState extends EntityState<MarketColumn> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'dashboardColumn', resettable: true })
export class MarketColumnStore extends EntityStore<
  IMarketColumnState,
  MarketColumn
> {
  constructor() {
    super();
  }
}
