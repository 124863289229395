@if (isLoading$ | async) {
  <mat-progress-bar
    mode="indeterminate"
  ></mat-progress-bar>
}
@if (data$ | async; as data) {
  <div
    class="flex flex-col lg:flex-row lg:h-56 space-x-4"
    >
    <cc-line-chart
      [data]="data.chart"
      class="w-full lg:w-3/4"
      [legend]="legend()"
    ></cc-line-chart>
    <div class="w-full lg:w-1/4">
      <div class="text-center">
        {{ 'market-dashboard-details.BOLLINGER_TITLE' | transloco }}
      </div>
      <table class="table-fixed">
        <tbody>
          <tr>
            <td class="w-56">
              {{ 'market-dashboard-details.BEFORE_LAST_DAY_PRICE' | transloco }}
            </td>
            <td class="w-28">
              {{ data.table.beforeLastDayPrice | number: '.2-2' }}
            </td>
            <td>{{ data.table.unit }}</td>
          </tr>
          <tr>
            <td>
              {{
              'market-dashboard-details.MA_NAME'
              | transloco: { value: bollingerPeriod }
              }}
            </td>
            <td>{{ data.table.sma | number: '.2-2' }}</td>
            <td>{{ data.table.unit }}</td>
          </tr>
          <tr>
            <td>
              {{ 'market-dashboard-details.BOLLINGER_UPPER' | transloco }}
            </td>
            <td>{{ data.table.upper | number: '.2-2' }}</td>
            <td>{{ data.table.unit }}</td>
          </tr>
          <tr>
            <td>
              {{ 'market-dashboard-details.BOLLINGER_LOWER' | transloco }}
            </td>
            <td>{{ data.table.lower | number: '.2-2' }}</td>
            <td>{{ data.table.unit }}</td>
          </tr>
          <tr>
            <td>
              {{ 'market-dashboard-details.BOLLINGER_WIDTH' | transloco }}
            </td>
            <td>{{ data.table.width | number: '.2-2' }}</td>
            <td>{{ data.table.unit }}</td>
          </tr>
        </tbody>
      </table>
      <ul class="list-disc !m-5">
        <li class="break-words">
          {{ 'market-dashboard-details.BOLLINGER_TIP' | transloco }}
        </li>
        <li class="break-words">
          {{ 'market-dashboard-details.BOLLINGER_TIP2' | transloco }}
        </li>
      </ul>
    </div>
  </div>
}
