<div class="flex flex-col sm:flex-row sm:space-x-2 sm:justify-between">
  <cc-time-span-toggle
    [timeFrame]="selectedTimeFrame$"
    [deliveryDay]="deliveryDay"
  ></cc-time-span-toggle>
</div>
@if (dataSource$ | async; as dataSource) {
  <div class="flex flex-col">
    <fof-index-chart
      class="!m-5"
      [identifier]="dataSource"
      [timeFrame]="selectedTimeFrame$ | async"
      [hideClosingText]="hideClosingValueHeader"
      (deliveryDayChanged)="deliveryDayChanged($event)"
      [legend]="legend()"
      >
    </fof-index-chart>
    @if (dataSource?.additionalDataSources?.length > 0) {
      <fof-comparison-chart
        class="!m-5 h-96"
        [legend]="legend()"
        [params]="comparisonData$ | async"
        >
      </fof-comparison-chart>
    }
  </div>
  @if (dataSource) {
    <div class="flex flex-col">
      !! TODO update angular v18
      [spotConfigurationId]="+dataSource?.spot?.spotConfigurationId"
      [spotType]="dataSource?.spot?.spotType" *ngIf="dataSource?.spot?.enabled"
      <fof-spot-chart class="!m-5" [legend]="legend()"> </fof-spot-chart>
      @if (dataSource?.aroon?.enabled) {
        <fof-aroon-chart
          class="!m-5"
          [identifier]="dataSource"
          [timeFrame]="selectedTimeFrame$ | async"
          [settings]="dataSource.aroon"
          [legend]="legend()"
        ></fof-aroon-chart>
      }
      @if (dataSource?.sma?.enabled) {
        <fof-ma-chart
          class="!m-5"
          [identifier]="dataSource"
          [timeFrame]="selectedTimeFrame$ | async"
          [settings]="dataSource.sma"
          [legend]="legend()"
        ></fof-ma-chart>
      }
      @if (dataSource?.bollinger?.enabled) {
        <fof-bollinger-band-chart
          class="!m-5"
          [identifier]="dataSource"
          [timeFrame]="selectedTimeFrame$ | async"
          [settings]="dataSource.bollinger"
          [legend]="legend()"
        ></fof-bollinger-band-chart>
      }
    </div>
  }
}
