import { Injectable } from '@angular/core';
import { EnumService } from '@backoffice-frontend/shared/bo/util-masterdata';
import { EnumValue } from '@clean-code/shared/bo/util-enum';
import { ID, ObjectHelper } from '@clean-code/shared/common';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MarketWidgetStore } from '../+state/widget.store';
import {
  MarketWidget,
  MarketWidgetDataSource,
} from './../models/market-dashboard';

@Injectable({ providedIn: 'root' })
export class MarketWidgetDataService {
  public static readonly marketWidgetParams = `
        id
        name
        settings
        dataSources
        {
            settings
        }
        groupId
    `;

  private readonly entityName = 'marketWidget';
  private readonly inputModelName = 'MarketWidgetInput';

  constructor(
    private store: MarketWidgetStore,
    private graphqlService: GraphqlService,
    private enumService: EnumService
  ) {}

  getAll$(id: ID): Observable<MarketWidget[]> {
    const query = `
            query($id: Int!) {
                ${this.entityName}GetAll(id: $id) {
                    ${MarketWidgetDataService.marketWidgetParams}
                }
            }
        `;

    return this.graphqlService.query<MarketWidget[]>(query, { id });
  }

  getById$(id: ID): Observable<MarketWidget> {
    const query = `
            query($id: Int!) {
                ${this.entityName}GetById(id: $id) {
                    ${MarketWidgetDataService.marketWidgetParams}
                }
            }
        `;

    return this.graphqlService.query<MarketWidget>(query, { id });
  }

  add$(input: MarketWidget): Observable<MarketWidget> {
    const mutation = `
            mutation ($input: ${this.inputModelName}!) {
                ${this.entityName}Add(input: $input)
                {
                    ${MarketWidgetDataService.marketWidgetParams}
                }
            }
        `;

    const clone = ObjectHelper.cloneObject(input);
    this.updateSettings(clone);

    const variables = {
      input: clone,
      // input: {
      //   ...clone,
      //   dataSources: clone.dataSources.map((x: any) => {
      //     return {
      //       ...x,
      //       identifier: (x.identifier as any).identifier,
      //     };
      //   }),
      // },
    };

    return this.graphqlService.mutation<MarketWidget>(mutation, variables).pipe(
      tap((value: MarketWidget) => {
        input.id = value.id;
        this.store.upsert(value.id, input);
      })
    );
  }

  update$(input: MarketWidget): Observable<MarketWidget> {
    const mutation = `
            mutation ($input: ${this.inputModelName}!) {
                ${this.entityName}Update(input: $input)
                {
                    ${MarketWidgetDataService.marketWidgetParams}
                }
            }
        `;

    const clone = ObjectHelper.cloneObject(input);
    this.updateSettings(clone);

    const variables = {
      input: clone,
      // input: {
      //   ...clone,
      //   dataSources: clone.dataSources.map((x: any) => {
      //     return {
      //       ...x,
      //       identifier: (x.identifier as any).identifier,
      //     };
      //   }),
      // },
    };

    return this.graphqlService
      .mutation<MarketWidget>(mutation, variables)
      .pipe(tap((_) => this.store.upsert(input.id, input)));
  }

  delete$(id: ID): Observable<boolean> {
    const mutation = `
            mutation {
                ${this.entityName}Delete(id: ${id})
            }
        `;

    return this.graphqlService
      .mutation<boolean>(mutation)
      .pipe(tap((_) => this.store.remove(id)));
  }

  private updateSettings(value: MarketWidget) {
    const payload = value;
    (payload.settings as unknown) = JSON.stringify(payload.settings);

    payload.dataSources.forEach((x: MarketWidgetDataSource) => {
      (x.settings as unknown) = JSON.stringify(x.settings); //data is sent as json string to backend
    });
  }

  public timeFrames$(): Observable<EnumValue[]> {
    return this.enumService.getEnumType$('TimeSeriesTimeFrame');
  }
}
