import { CommonModule } from '@angular/common';
import { Component, Input, input } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DashboardFacade } from '@backoffice-frontend/market/domain';
import { TimeFrame } from '@backoffice-frontend/shared/bo/util-masterdata';
import { BollingerBand } from '@backoffice-frontend/shared/ui-statistics';
import {
  BollingerBandSummary,
  BollingerSettings,
  ClosingValue,
  LineChartBaseChart,
  TimeSeriesFacade,
  TimeSeriesInput,
} from '@backoffice-frontend/time-series-identifier/domain';
import { indicate } from '@clean-code/shared/common';
import {
  DevExtremeLineChartComponent,
  LineChartDataModel,
  LineChartModel,
} from '@clean-code/shared/components/ui-devextreme-charts';
import { TranslocoModule } from '@jsverse/transloco';
import { TitleOptions } from 'chart.js';
import { EMPTY } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'fof-bollinger-band-chart',
  templateUrl: './bollinger-band-chart.component.html',
  imports: [
    CommonModule,
    MatProgressBarModule,
    TranslocoModule,
    DevExtremeLineChartComponent,
  ],
})
export class BollingerBandChartComponent extends LineChartBaseChart<BollingerSettings> {
  public bollingerPeriod: number;

  @Input()
  public set identifier(value: TimeSeriesInput) {
    this.identifier$.next(value);
  }

  @Input()
  public set timeFrame(value: TimeFrame) {
    this.selectedTimeFrame$.next(value);
  }

  @Input()
  public set settings(value: BollingerSettings) {
    this.bollingerPeriod = value.period;
    this.chartSettings$.next(value);
  }

  public legend = input(false);

  public yAxisTitle: TitleOptions;

  public data$ = this.paramChange$.pipe(
    switchMap(
      ([value, settings, timeFrame]: [
        TimeSeriesInput,
        BollingerSettings,
        TimeFrame,
      ]) => {
        return this.timeSeriesFacade
          .getBollingerBandData$(value.identifier, settings, timeFrame)
          .pipe(
            map((bollingerBandSummery: any) => {
              return bollingerBandSummery;
            }),
            indicate(this.isLoading$),
          );
      },
    ),
    map((values: BollingerBandSummary) => {
      const array = new Array<any>();

      const closedData = {
        label: values.name,
        yAxisTitle: values.unit,
        data: values.closingValues.map(
          (val: ClosingValue) =>
            ({
              date: new Date(val.tradeDate),
              value: val.value,
            }) as LineChartDataModel,
        ),
      } as LineChartModel;

      array.push(closedData);

      const upper = values.bollinger.map(
        (value: BollingerBand) =>
          ({
            date: new Date(value.date),
            value: value.upperBand,
          }) as LineChartDataModel,
      );
      const lower = values.bollinger.map(
        (value: BollingerBand) =>
          ({
            date: new Date(value.date),
            value: value.lowerBand,
          }) as LineChartDataModel,
      );
      const sma = values.bollinger.map(
        (value: BollingerBand) =>
          ({
            date: new Date(value.date),
            value: value.sma,
          }) as LineChartDataModel,
      );

      array.push({
        yAxisTitle: values.unit,
        label: 'Upper Bollinger',
        data: upper,
      } as LineChartModel);
      array.push({
        yAxisTitle: values.unit,
        label: 'Lower Bollinger',
        data: lower,
      } as LineChartModel);
      array.push({
        yAxisTitle: values.unit,
        label: 'MA' + this.bollingerPeriod,
        data: sma,
      } as LineChartModel);

      return { chart: array, table: values.table };
    }),
  );

  details$ = EMPTY;

  constructor(
    private timeSeriesFacade: TimeSeriesFacade,
    public dashboardFacade: DashboardFacade,
  ) {
    super();
  }
}
