import { Component, Input } from '@angular/core';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faLongArrowDown as faLongArrowDownLight } from '@fortawesome/pro-light-svg-icons';
import { faLongArrowDown as faLongArrowDownSolid } from '@fortawesome/pro-solid-svg-icons';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'fof-trend-icon',
  templateUrl: './trend-icon.component.html',
  styleUrls: ['./trend-icon.component.scss'],
})
export class TrendIconComponent {
  public rotate$ = new BehaviorSubject<number>(0);
  public arrowIconLight = faLongArrowDownLight;
  public arrowIconSolid = faLongArrowDownSolid;

  @Input() public set deltaPercent(value: number) {
    this.rotate$.next(this.rotate(value));
  }

  @Input() public set deltaAbsolute(value: number) {
    this.rotate$.next(this.rotate(value, true));
  }

  @Input() public iconSize: SizeProp = '1x';

  @Input() public iconStyle: 'light' | 'solid' = 'light';

  private rotate(delta: number, absolute = false): number {
    if (absolute) {
      if (delta === null || delta === undefined || delta === 0) {
        return 270;
      }

      return delta > 0 ? 180 : 360;
    }

    const diffDelta = delta;
    if (delta === 0) {
      return 270; //straight, right
    }

    if (diffDelta > 0 && diffDelta > 0.05) {
      return 180; //up
    }
    if (diffDelta > 0 && diffDelta < 0.05) {
      return 225; //up right
    } else if (diffDelta < 0 && diffDelta < -0.05) {
      return 360; //down
    } else if (diffDelta < 0 && diffDelta > -0.05) {
      return 315; //down right
    }
    return 270; //straight, right
  }
}
