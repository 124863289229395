import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { EMPTY } from 'rxjs';

@Component({
  selector: 'cc-chart-container',
  templateUrl: './chart-container.component.html',
  styleUrls: ['./chart-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ChartJSContainerComponent {
  @HostBinding('class') class = 'cc-chart-container';
  @Input()
  public isLoading = false;

  public isMobile$ = EMPTY;
}
